import React from "react"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Container from "react-bootstrap/Container"
import "./materials.scss"
import { window } from "browser-monads"
import Img from "gatsby-image"


export default function Decor() {
    const data = useStaticQuery(graphql`
  query {
    decorImageOne: file(relativePath: { eq: "decor_1.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    decorImageTwo: file(relativePath: { eq: "decor_2.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    decorImageThree: file(relativePath: { eq: "decor_3.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    decorImageFour: file(relativePath: { eq: "decor_4.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    decorImageFive: file(relativePath: { eq: "decor_5.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    decorImageSix: file(relativePath: { eq: "decor_6.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    decorImageSeven: file(relativePath: { eq: "decor_7.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    decorImageEight: file(relativePath: { eq: "decor_8.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    decorImageNine: file(relativePath: { eq: "decor_9.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    decorImageTen: file(relativePath: { eq: "decor_10.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    decorImageEleven: file(relativePath: { eq: "decor_11.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    decorImageTwelve: file(relativePath: { eq: "decor_12.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    decorImageThirteen: file(relativePath: { eq: "decor_13.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    decorImageFourteen: file(relativePath: { eq: "decor_14.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    decorImageFifteen: file(relativePath: { eq: "decor_15.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    decorImageSixteen: file(relativePath: { eq: "decor_16.jpeg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorImageSeventeen: file(relativePath: { eq: "decor_17.jpeg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorImageEighteen: file(relativePath: { eq: "decor_18.jpeg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorImageNineteen: file(relativePath: { eq: "decor_19.jpeg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorImageTwenty: file(relativePath: { eq: "decor_20.jpeg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorImageTwentyOne: file(relativePath: { eq: "decor_21.jpeg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorImageTwentyTwo: file(relativePath: { eq: "decor_22.jpeg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorImageTwentyThree: file(relativePath: { eq: "decor_23.jpeg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorImageTwentyFour: file(relativePath: { eq: "decor_24.jpeg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
  }
  `)
    return (

        <Layout>
            <SEO title="Декор" />

            <h1 className="text-center">
                Декор
    </h1>
            <Container>

                <div className="card-group vgr-cards">
                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageOne.childImageSharp.fluid} />
                        </div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageTwo.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>

                    <div className="card py-3">
                        <div className="card-img-body">
                            <Img fluid={data.decorImageThree.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>

                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageFour.childImageSharp.fluid} />
                        </div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageFive.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body">
                            <Img fluid={data.decorImageSix.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageSeven.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageEight.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body">
                            <Img fluid={data.decorImageNine.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageTen.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageEleven.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body">
                            <Img fluid={data.decorImageTwelve.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageThirteen.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageFourteen.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageFifteen.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageSixteen.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageSeventeen.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageEighteen.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body">
                            <Img fluid={data.decorImageNineteen.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageTwenty.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageTwentyOne.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body">
                            <Img fluid={data.decorImageTwentyTwo.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageTwentyThree.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                    <div className="card py-3">
                        <div className="card-img-body" style={{ maxHeight: '500px', }}>
                            <Img fluid={data.decorImageTwentyFour.childImageSharp.fluid} /></div>
                        <div className="card-body">
                        </div>
                    </div>
                </div>

            </Container>
        </Layout>
    )

}